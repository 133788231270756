<template>
  <div class="full" style="background-color: #f6f6f6">
    <div class="full flex-column no-expand" style="position: relative">
      <van-dialog
        v-model="show"
        :title="cateForm.id ? '编辑分类' : '新增分类'"
        show-cancel-button
        :before-close="beforeChange"
      >
        <div class="no-expand">
          <van-form ref="cateForm" :disabled="cateFormLoading">
            <van-field
              v-model.trim="cateForm.name"
              label="分类名称"
              placeholder="请输入分类名称"
              :maxlength="15"
              :rules="[{ required: true, message: '请填写分类名称' }]"
            />
            <van-field
              v-model="cateForm.sort"
              label="分类序号"
              placeholder="请输入分类序号"
              :maxlength="4"
              type="digit"
              :rules="[
                {
                  pattern: /^\+?[1-9][0-9]{0,2}$/,
                  message: '请填写1-999的整数',
                },
              ]"
            />
            <van-field v-model="cateForm.discount" label="折扣力度">
              <template #input>
                <van-slider v-model="cateForm.discount" active-color="#ee0a24">
                  <template #button>
                    <div class="custom-button">
                      {{
                        cateForm.discount === "0" || cateForm.discount === null
                          ? "无折扣"
                          : (100 - cateForm.discount) / 10 + "折"
                      }}
                    </div>
                  </template>
                </van-slider>
              </template>
            </van-field>
          </van-form>
        </div>
      </van-dialog>
      <overlay :show="loading">
        <div class="wrapper">
          <loading type="spinner" size="30" />
        </div>
      </overlay>
      <div class="navi-container">
        <tabs border v-model="currentIndex">
          <template #nav-left>
            <van-button
              class="top-cate"
              icon="plus"
              type="primary"
              @click="newCategory"
              >分类</van-button
            >
          </template>
          <tab
            v-for="c in category"
            :key="c.id"
            :title="c.name"
            :title-style="{ 'font-size': '0.6rem' }"
          />
        </tabs>
      </div>
      <div
        v-if="category[currentIndex]"
        class="flex-row no-expand edit-cate"
        style="padding: 0.2rem 0.2rem 0.2rem 0.2rem"
      >
        <div style="width: 6rem">
          <van-switch v-model="isEdit" size="0.8rem" />
        </div>
        <van-button
          style="flex: 1; background: #3a8ffc"
          type="info"
          round
          size="mini"
          @click="alterCategory"
          ><van-icon name="edit"></van-icon>
          {{ category[currentIndex].name }}</van-button
        >
        <div style="width: 0.5rem"></div>
        <van-button
          style="flex: 1; background: #3a8ffc"
          type="info"
          round
          size="mini"
          :loading="deleteCateLoading"
          :disabled="goods.length > 0"
          @click="deleteCategory"
          ><van-icon name="delete"></van-icon>
          {{ category[currentIndex].name }}</van-button
        >
      </div>
      <div class="food-list no-expand" v-if="goods.length > 0">
        <scroll ref="bigScroll">
          <div
            class="flex-row"
            style="
              padding: 0.5rem 0.3rem;
              background-color: #f6f6f6;
              flex-wrap: wrap;
            "
          >
            <div
              v-for="(_i, _index) in goods"
              class="goods-box"
              :style="_index === 0 ? { marginTop: 0 } : {}"
              :key="_i.id"
            >
              <div class="box-inner">
                <div class="opt-btn" v-if="isEdit">
                  <img
                    v-if="_i.status == 0"
                    src="../../assets/image/offline.png"
                    style="width: 1rem; height: 1rem"
                    @click="offGood(_i.id, 2)"
                  />
                  <img
                    v-if="_i.status == 2"
                    src="../../assets/image/online.png"
                    style="width: 1rem; height: 1rem"
                    @click="offGood(_i.id, 0)"
                  />
                  <img
                    src="../../assets/image/edit.png"
                    style="width: 1rem; height: 1rem; margin-left: 0.3rem"
                    @click="editFood(_i)"
                  />
                  <img
                    src="../../assets/image/del.png"
                    style="width: 1rem; height: 1rem; margin-left: 0.3rem"
                    @click="deleteGood(_i.id)"
                  />
                </div>
                <div>
                  <div style="position: relative">
                    <div
                      v-if="_i.status === 2"
                      style="position: absolute; z-index: 5"
                      class="ab-center"
                    >
                      <van-tag color="#F76045" size="large" plain
                        >已下架</van-tag
                      >
                    </div>
                    <van-image
                      width="100%"
                      height="2.7rem"
                      fit="cover"
                      style="border-radius: 10px; overflow: hidden"
                      :src="_i.img_url"
                    >
                      <template v-slot:loading>
                        <van-loading type="spinner" size="20" />
                      </template>
                    </van-image>
                  </div>
                  <div
                    class="flex-row storage"
                    style="
                      justify-content: space-between;
                      align-items: flex-end;
                      padding: 0.1rem 0 0.3rem 0;
                    "
                  >
                    <div class="food-name van-ellipsis">
                      {{ _i.name }}
                    </div>
                    <div class="price" style="flex-shrink: 0">
                      <template v-if="_i.member_price">
                        <span>¥ {{ _i.member_price / 100 }}</span>
                      </template>
                      <template v-else>
                        <span>¥ {{ _i.price / 100 }}</span>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </scroll>
      </div>
      <draggable
        :limit="{ y: 50 }"
        :position="{ right: '0.8rem', bottom: '3rem' }"
      >
        <div>
          <van-button
            style="height: 2.5rem; width: 2.5rem"
            class="three-d-shadow-mid"
            round
            @click="addGood"
            icon="plus"
            type="primary"
          />
        </div>
      </draggable>
      <popup
        round
        v-model="showDetail"
        close-icon="close"
        position="bottom"
        safe-area-inset-bottom
        :lock-scroll="false"
        :style="{ height: '23.5rem' }"
        :closeable="!uploading"
        :close-on-click-overlay="!uploading"
        @opened="chooseCate() && $refs.scroll.refresh()"
      >
        <scroll ref="scroll">
          <div class="form-container">
            <van-form ref="form">
              <van-field
                v-model="form.name"
                label="菜品名"
                placeholder="菜品名"
                :maxlength="15"
                :rules="[{ required: true, message: '请填写菜品名' }]"
              />
              <van-field
                v-model="form.img_url"
                label="菜品封面"
                :rules="[{ required: true, message: '请上传封面' }]"
              >
                <template #input>
                  <van-uploader
                    :before-read="beforeRead"
                    :after-read="afterRead"
                    v-model="extra.uploader"
                    :max-count="1"
                    @delete="form.img_url = null"
                  />
                </template>
              </van-field>
              <van-field
                v-model="form.introduce"
                rows="2"
                autosize
                label="菜品简介"
                type="textarea"
                maxlength="160"
                placeholder="请输入简介"
                show-word-limit
              />
              <van-field
                readonly
                clickable
                :value="extra.cate_name"
                label="所属分类"
                placeholder="点击选择分类"
                @click="chooseCate"
                :rules="[{ required: true, message: '请选择分类' }]"
              />
              <popup round v-model="showPicker" position="bottom">
                <van-picker
                  show-toolbar
                  :columns="columns"
                  @confirm="showPicker = true"
                  @cancel="showPicker = false"
                />
              </popup>
              <van-field
                v-model="form.member_price"
                label="实际单价"
                placeholder="实际单价"
                type="number"
                :maxlength="13"
                :rules="[
                  {
                    pattern:
                      /^(?:0\.\d?[1-9]|(?!0)\d{1,10}(?:\.\d?[1-9])?)$/,
                    message: '实际单价',
                    //message: '请输入至多小数点前10位后2位的数字',
                  },
                  {
                    validator,
                    message: '实际单价必须小于等于划线单价',
                  },
                ]"
              />
              <van-field
                v-model="form.price"
                label="划线单价"
                placeholder="划线单价"
                type="number"
                :maxlength="13"
                :rules="[
                  {
                    pattern: /^(?:0\.\d?[1-9]|(?!0)\d{1,10}(?:\.\d?[1-9])?)$/,
                    message: '请输入划线单价',
                    //message: '请输入至多小数点前10位后2位的数字',
                  },
                ]"
              />
              <van-field
                v-model="form.stock"
                label="菜品库存"
                placeholder="菜品库存"
                :maxlength="4"
                :rules="[
                  {
                    pattern: /^\+?(0|[1-9][0-9]{0,3})$/,
                    message: '请填写0-9999的整数',
                  },
                ]"
              />
              <van-field
                v-model="form.sort"
                label="菜品序号"
                placeholder="请输入菜品序号"
                :maxlength="4"
                type="digit"
                :rules="[
                  {
                    pattern: /^\+?[1-9][0-9]{0,2}$/,
                    message: '请填写1-999的整数',
                  },
                ]"
              />
              <van-field
                v-model="form.is_top"
                label="是否置顶"
                :rules="[{ required: true, message: '请选择是否置顶' }]"
              >
                <template #input>
                  <van-radio-group v-model="form.is_top" direction="horizontal">
                    <van-radio name="0">否</van-radio>
                    <van-radio name="1">是</van-radio>
                  </van-radio-group>
                </template>
              </van-field>
            </van-form>
            <div class="button-group">
              <div style="padding: 0.2rem 0.1rem; flex: 1">
                <van-button
                  style="width: 100%"
                  type="info"
                  plain
                  round
                  :disabled="uploading"
                  @click="resetFood"
                >
                  重置
                </van-button>
              </div>
              <div style="padding: 0.2rem 0.1rem; flex: 1">
                <van-button
                  style="width: 100%"
                  type="primary"
                  round
                  :disabled="uploading"
                  @click="requestGoods"
                >
                  确认
                </van-button>
              </div>
            </div>
          </div>
        </scroll>
      </popup>
    </div>
  </div>
</template>

<script>
import {
  Button as VanButton,
  Dialog,
  Field as VanField,
  Form as VanForm,
  Loading,
  Loading as VanLoading,
  Overlay,
  Picker as VanPicker,
  Popup,
  Radio as VanRadio,
  RadioGroup as VanRadioGroup,
  Slider as VanSlider,
  Tab,
  Tabs,
  Tag as VanTag,
  Uploader as VanUploader,
  Image as VanImage,
  Switch as VanSwitch,
  Icon as VanIcon,
} from "vant";
import {
  addCategory,
  addGood,
  deleteCategory,
  editCategory,
  selectCategoryList,
  selectGoodList,
  uploadImage,
  editGood,
  deleteGood,
  offGood,
} from "@/api/merchant";
import { mapState } from "vuex";
import Scroll from "@/components/scroll";
import Draggable from "@/components/draggable";

export default {
  name: "MerchantMenu",
  components: {
    Draggable,
    Scroll,
    Tab,
    Tabs,
    VanTag,
    VanButton,
    Loading,
    VanLoading,
    Overlay,
    Popup,
    VanForm,
    VanField,
    VanUploader,
    VanPicker,
    VanSlider,
    VanRadio,
    VanRadioGroup,
    VanImage,
    VanSwitch,
    VanIcon,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      category: [],
      goods: [],
      currentIndex: 0,
      loading: false,
      showDetail: false,
      showPicker: false,
      show: false,
      isEdit: false,
      extra: {
        uploader: [],
        cate_name: null,
      },
      form: {
        id: null,
        mer_id: null,
        name: null,
        img_url: null,
        introduce: null,
        cate_id: null,
        sort: null,
        price: null,
        member_price: null,
        stock: null,
        is_top: null,
        remark: null,
      },
      cateFormLoading: false,
      deleteCateLoading: false,
      cateForm: {
        id: null,
        mer_id: null,
        name: null,
        sort: null,
        discount: null,
      },
      uploading: false,
    };
  },
  watch: {
    currentIndex() {
      this.refreshGoods();
    },
    form: {
      handler() {
        this.$nextTick(() => {
          this.$refs.scroll.refresh();
        });
      },
      deep: true,
    },
    $route(to) {
      if (to.name === "MerchantMenu") {
        this.refreshCategoryAndGoods();
      }
    },
  },
  computed: {
    columns() {
      return this.category.map((val) => val.name);
    },
    ...mapState({
      merId: (state) => state.base.merchantId,
    }),
  },
  mounted() {
    this.refreshCategoryAndGoods();
  },
  methods: {
    refreshCategoryAndGoods() {
      const vm = this;
      const merId = vm.merId;
      this.loading = true;
      selectCategoryList(merId)
        .then((res) => {
          this.category = res.data;
          this.loading = false;
          this.refreshGoods(true);
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$toast.fail("获取分类失败，请刷新重试");
        });
    },
    newCategory() {
      Object.keys(this.cateForm).forEach((key) => {
        this.cateForm[key] = null;
      });
      this.cateForm.mer_id = this.merId;
      this.cateForm.discount = 0;
      this.show = true;
    },
    alterCategory() {
      const t = this.category[this.currentIndex];
      Object.keys(this.cateForm).forEach((key) => {
        this.cateForm[key] = t[key];
      });
      this.cateForm.discount = 100 - Number(this.cateForm.discount);
      this.show = true;
    },
    deleteCategory() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "是否删除该分类？",
        })
        .then(() => {
          this.deleteCateLoading = true;
          deleteCategory(this.category[this.currentIndex].id)
            .then(() => {
              this.refreshCategoryAndGoods();
              this.deleteCateLoading = false;
            })
            .catch(() => {
              this.deleteCateLoading = false;
              this.$toast.fail("删除分类失败，请稍后再试");
            });
        });
    },
    refreshGoods(force) {
      if (this.category.length === 0) {
        return;
      }
      const index = this.currentIndex > -1 ? this.currentIndex : 0;
      const cateId = this.category[index]?.id;
      if (this.category[index] && this.category[index].children && !force) {
        this.goods = this.category[index].children;
      } else {
        if (!cateId) this.currentIndex = 0;
        this.loading = true;
        selectGoodList(
          this.merId,
          cateId || this.category[this.currentIndex].id
        )
          .then((res) => {
            this.loading = false;
            this.goods = res.data;
            this.$set(this.category[index], "children", res.data);
          })
          .catch(() => {
            this.loading = false;
            this.$toast.fail("获取菜品列表失败，请稍后再试");
          });
      }
    },
    beforeChange(action, done) {
      if (action === "confirm") {
        let method = editCategory;
        if (this.cateForm.id) {
          method = editCategory;
          // edit mode
        } else {
          method = addCategory;
          // add mode
        }
        this.$refs.cateForm
          .validate()
          .then(() => {
            this.cateFormLoading = true;
            this.cateForm.discount = 100 - Number(this.cateForm.discount);
            method(this.cateForm)
              .then(() => {
                this.refreshCategoryAndGoods();
                this.cateFormLoading = false;
                done();
              })
              .catch(() => {
                this.cateFormLoading = false;
                this.cateForm.discount = 100 - Number(this.cateForm.discount);
                this.$toast.fail("网络请求失败，请稍后再试");
              });
          })
          .catch(() => {
            done(false);
          });
      } else {
        done();
      }
    },
    addGood() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = null;
      });
      this.extra.cate_name = null;
      this.extra.uploader = [];
      this.form.mer_id = this.merId;
      this.form.is_top = "0";
      this.form.stock = 9999;
      this.showDetail = true;
    },
    chooseCate() {
      this.extra.cate_name = this.category[this.currentIndex].name;
      this.form.cate_id = this.category[this.currentIndex].id;
      return true;
    },
    onConfirm(value, index) {
      this.extra.cate_name = value;
      this.form.cate_id = this.category[index].id;
      this.showPicker = false;
    },
    validator(val) {
      if (val) {
        return Number(val) <= Number(this.form.price);
      }
      return true;
    },
    beforeRead(file) {
      this.uploading = true;
      file.status = "uploading";
      file.message = "读取中...";
      return true;
    },
    afterRead(file) {
      file.status = "uploading";
      file.message = "上传中...";
      this.form.img_url = null;
      uploadImage(file)
        .then((res) => {
          this.form.img_url = res.data;
          file.status = "done";
          file.message = "上传成功";
          this.uploading = false;
        })
        .catch(() => {
          file.status = "failed";
          file.message = "上传失败";
          this.uploading = false;
          this.$toast.fail("上传图片失败，请稍后再试");
        });
    },
    resetFood() {
      for (let key in this.form) {
        key !== "merId" && (this.form[key] = null);
      }
      this.extra.uploader = [];
      this.extra.cate_name = null;
      this.$refs.form.resetValidation();
    },
    editFood(food) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = food[key];
      });
      this.extra.uploader = [{ url: food.img_url, isImage: true }];
      this.extra.cate_name = this.category.find(
        (value) => value.id === food.cate_id
      ).name;
      this.form.price = this.form.price / 100;
      this.form.member_price &&
        (this.form.member_price = this.form.member_price / 100);
      this.showDetail = true;
    },
    requestGoods() {
      const method = this.form.id ? editGood : addGood;
      this.$refs.form.validate().then(() => {
        this.showDetail = false;
        this.loading = true;
        this.form.price = this.form.price * 100;
        this.form.member_price &&
          (this.form.member_price = this.form.member_price * 100);
        method(this.form)
          .then(() => {
            this.currentIndex = this.category.findIndex(
              (value) => value.id === this.form.cate_id
            );
            this.refreshGoods(true);
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            this.form.price = this.form.price / 100;
            this.form.member_price &&
              (this.form.member_price = this.form.member_price / 100);
            this.$toast.fail("网络请求失败，请稍后再试");
          });
      });
    },
    deleteGood(id) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "是否删除该商品？",
        })
        .then(() => {
          this.loading = true;
          deleteGood(id)
            .then(() => {
              this.refreshGoods(true);
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
              this.$toast.fail("删除失败，请稍后再试");
            });
        });
    },
    offGood(id, status) {
      this.$dialog
        .confirm({
          title: "提示",
          message: `是否${status === 0 ? "上架" : "下架"}该商品？`,
        })
        .then(() => {
          this.loading = true;
          offGood(id, status)
            .then(() => {
              this.refreshGoods(true);
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
              this.$toast.fail("下架失败，请稍后再试");
            });
        });
    },
  },
};
</script>

<style scoped lang="less">
.food-list {
  flex: 1;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.form-container {
  padding: 30px 10px;
}
.button-group {
  display: flex;
  justify-content: space-around;
}
/deep/.custom-button {
  width: auto;
  word-break: keep-all;
  padding: 0 5px;
  color: #fff;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  background-color: #ee0a24;
  border-radius: 100px;
}
/deep/ .van-tabs__line {
  background-color: @mch-color;
  width: 3rem;
}
/deep/ .van-tab--active {
  color: @mch-color;
}
/deep/ .van-button--mini {
  font-size: 0.5rem;
}
/deep/ .van-button--primary {
  color: #fff;
  background-color: @mch-color;
  border: 0.04rem solid @mch-color;
}
.top-cate {
  border-radius: 0.5rem;
  background: @mch-color;
  border: 0.04rem solid @mch-color;
}
.edit-cate {
  padding: 0.2rem;
  background: #dae6f2;
  margin-top: 0.3rem;
  height: 1rem;
  margin-bottom: 0.4rem;
  min-width: 3.5rem;
}

.goods-box {
  width: 50%;
  .box-inner {
    position: relative;
    border-radius: 0.3rem;
    padding: 0.3rem;
    background-color: #fff;
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
    box-shadow: 0 0 3px #a1dcff;
  }
}
.opt-btn {
  height: 1rem;
  position: absolute;
  transform: translateY(-40%);
  z-index: 100;
  right: 0;
  top: 0;
}
</style>
